import { Input as AInput, Col, Row } from 'antd';
import Form, { Item } from 'components/Form/Form';
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import Button from 'components/TrackingComponents/Button';
import { FormattedMessage } from 'react-intl';
import Input from 'components/TrackingComponents/Input';
import messages from './messages';
import styled from 'styles/styled-components';
import utilsMessages from 'utils/messages';
import { checkEmailRegex } from 'utils/utilities';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';

const InviteInput = styled(AInput)<any>``;

const InviteContainer = styled.div`
  ${InviteInput} {
    margin-bottom: 8pt;
  }
`;

const InviteActions = styled.div`
  text-align: right;
`;

interface InviteFormProps {
  form: any;
  isCheckingEmail: boolean;
  validationEmailMessage: any;
  inviteLoading: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  checkEmail: (data: any) => void;
}

class InviteFormInner extends Component<InviteFormProps> {
  public state = {
    emailNumber: 3,
  };

  public emailValidationCallback: any = {};

  public componentWillReceiveProps(nextProps: any) {
    const keys = Object.keys(nextProps.validationEmailMessage);

    keys.forEach((key) => {
      if (this.emailValidationCallback[key]) {
        this.emailValidationCallback[key](nextProps.validationEmailMessage[key] || undefined);
        this.emailValidationCallback[key] = null;
      }
    });
  }

  public onSubmit = (e: Event) => {
    e.preventDefault();
    this.props.form
      .validateFields((err: any, values: any) => {
        if (!err) {
          const emailKeys = Object.keys(values);
          const emails: string[] = [];
          emailKeys.forEach((key) => {
            const value = values[key] as string;
            if (value && emails.indexOf(value) === -1) {
              emails.push(value);
            }
          });
          this.props.form.resetFields();
          this.props.onSubmit(emails);
        }
      })
      .catch((error) => Sentry.captureException(error, { level: Sentry.Severity.Warning }));
  };

  public checkEmail = (form: WrappedFormUtils, index) => (rule: any, value: any, callback: any) => {
    const email = form.getFieldValue(`email-${index}`);

    if (!checkEmailRegex.test(String(email).toLowerCase())) {
      return callback();
    }
    this.props.checkEmail({ id: index, email: email });
    this.emailValidationCallback[index] = callback;
  };

  public onAddMoreEmail = () => {
    this.setState({
      emailNumber: this.state.emailNumber + 1,
    });
  };

  public render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.onSubmit}>
        <Row>
          <Col span={24}>
            <Item label="Email">
              <InviteContainer>
                {Array(this.state.emailNumber)
                  .fill(0)
                  .map((_, index) => (
                    <FormattedMessage {...utilsMessages.fieldEmail} key={index}>
                      {(fieldEmail) => (
                        <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: 'Email' }}>
                          {(fieldRequired) => (
                            <Form.Item colon={false} required={false} hasFeedback>
                              {getFieldDecorator(`email-${index}`, {
                                rules: [
                                  {
                                    type: 'email',
                                    message: fieldEmail,
                                  },
                                  { required: false, message: fieldRequired },
                                  {
                                    validator: this.checkEmail(this.props.form, index),
                                  },
                                ],
                                normalize: (value: string) => value && value.replace(/\s/g, ''),
                              })(
                                <Input
                                  InputComponent={InviteInput}
                                  placeholder="Email"
                                  autoComplete="off"
                                  type="email"
                                  trackingCategory="User Management | Invite Colleagues | Input"
                                  trackingAction="Enter Email"
                                  const
                                />,
                              )}
                            </Form.Item>
                          )}
                        </FormattedMessage>
                      )}
                    </FormattedMessage>
                  ))}
              </InviteContainer>
              <InviteActions>
                <Button
                  trackingCategory="User Management | Invite Colleagues | Button"
                  trackingAction="Add More Email"
                  size="small"
                  ghost
                  style={{ color: '#40A9FF' }}
                  type="link"
                  onClick={this.onAddMoreEmail}
                >
                  + <FormattedMessage {...messages.addNew} />
                </Button>
              </InviteActions>
            </Item>
          </Col>

          <Col span={8} offset={8}>
            <Button
              trackingCategory="User Management | Invite Colleagues | Button"
              trackingAction="Cancel"
              block
              disabled={this.props.inviteLoading}
              type="secondary"
              ghost
              onClick={this.props.onCancel}
            >
              <FormattedMessage {...utilsMessages.cancel} />
            </Button>
          </Col>
          <Col span={8}>
            <Button
              trackingCategory="User Management | Invite Colleagues | Button"
              trackingAction="Invite"
              block
              loading={this.props.inviteLoading}
              type="primary"
              htmlType="submit"
            >
              <FormattedMessage {...messages.invite} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create({ name: 'InviteForm' })(InviteFormInner);
