import { call, fork, put, take } from 'redux-saga/effects';
import {
  checkEmail as checkEmailActions,
  invite as inviteActions,
  userStatus as userStatusActions,
  users as usersActions,
} from './actions';
import { checkValidEmail, getUsers, inviteBuyerUsers, updateUser } from 'utils/apollo';

import ActionTypes from './constants';
import { message } from 'antd';
import translations from 'translations';
import messages from './messages';

function* initDataFlow() {
  yield put(usersActions.request());
  const response = yield call(getUsers);
  if (!response.errors) {
    yield put(usersActions.success(response));
  }
}

function* inviteFlow() {
  while (true) {
    const {
      payload: { emails },
    } = yield take(ActionTypes.INVITE_USERS);
    yield put(inviteActions.request());
    const inviteResponse = yield call(inviteBuyerUsers, { emails: emails });
    const response = yield call(getUsers);
    if (!inviteResponse.errors) {
      yield put(inviteActions.success(response));
    } else {
      message.error(translations(messages.inviteFailed));
    }
  }
}

function* userStatusFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SET_USER_STATUS);
    yield put(userStatusActions.request());
    const response = yield call(updateUser, { input: payload });
    if (!response.errors) {
      yield put(userStatusActions.success({ ...response, ...payload }));
    } else {
      yield put(userStatusActions.failure(response.errors));
    }
  }
}

function* checkEmailFlow() {
  while (true) {
    const {
      payload: { email, id },
    } = yield take(ActionTypes.CHECK_EMAIL_SUBMIT);
    yield put(checkEmailActions.request({ id: id }));
    const response = yield call(checkValidEmail, {
      email: email,
    });
    if (!response.errors) {
      yield put(checkEmailActions.success(response));
    } else {
      yield put(checkEmailActions.failure({ message: response.errors[0].message, id: id }));
    }
  }
}

// Individual exports for testing
export default function* userManagementPageSaga() {
  yield fork(initDataFlow);
  yield fork(inviteFlow);
  yield fork(userStatusFlow);
  yield fork(checkEmailFlow);
}
