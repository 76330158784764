/**
 *
 * UserTable
 *
 */

import * as React from 'react';

import { Table } from 'antd';
import { User } from './types';
import { convertPermissionsToPermissionGroups } from 'utils/permissionMap';
import messages from './messages';
import styled from 'styled-components';
import translations from 'translations';

// import styled from 'styles/styled-components';

interface OwnProps {
  users: User[];
  onGotoUserDetails: (userId: string) => void;
  loading: boolean;
}

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 4px 4px 8px rgba(4, 15, 15, 0.12);
  .ant-table tbody tr {
    cursor: pointer;
  }
  .ant-table-thead > tr > th {
    background: #f6ffed;
  }
  tbody {
    background-color: white;
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Status = styled.div<{ active: boolean }>`
  width: 6px;
  height: 6px;
  background-color: ${(active) => (active ? '#52C41A' : '#E8E8E8')};
  border-radius: 3px;
  margin-right: 10pt;
`;
const Text = styled.span`
  font-weight: 300;
`;

const columns = [
  {
    title: translations(messages.username),
    key: 'username',
    render: (user) => (
      <UserContainer>
        <Status active={user.status === 'ACTIVE'} /> {user.name || user.email.split('@')[0]}
      </UserContainer>
    ),
    width: 220,
  },
  {
    title: translations(messages.groups),
    key: 'groups',
    render: (user) => <Text>{(user.userGroups || []).map((group) => group.name).join(', ')}</Text>,
    width: 220,
  },
  {
    title: translations(messages.permissions),
    key: 'permissions',
    width: 500,
    render: (user) => {
      const permissions = (user.permissions || []).map((permission) => permission.action);
      (user.userGroups || []).forEach((group) => {
        group.permissions.forEach((permission) => {
          if (permissions.indexOf(permission.action) === -1) {
            permissions.push(permission.action);
          }
        });
      });
      const permissionGroups = convertPermissionsToPermissionGroups(permissions);
      return <Text>{permissionGroups.map((p) => p.title).join(', ')}</Text>;
    },
  },
];

const UserTable: React.SFC<OwnProps> = (props: OwnProps) => {
  return (
    <Container>
      <Table
        loading={!!props.loading}
        dataSource={props.users}
        columns={columns}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => props.onGotoUserDetails(record.id),
          };
        }}
        scroll={{ y: 'calc(100vh - 380px)', x: 940 }}
      />
    </Container>
  );
};

export default UserTable;
