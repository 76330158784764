enum ActionTypes {
  FETCH_USERS_REQUEST = 'app/userManagementPage/FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS = 'app/userManagementPage/FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE = 'app/userManagementPage/FETCH_USERS_FAILURE',
  INVITE_USERS = 'app/userManagementPage/INVITE_USERS',
  INVITE_USERS_REQUEST = 'app/userManagementPage/INVITE_USERS_REQUEST',
  INVITE_USERS_SUCCESS = 'app/userManagementPage/INVITE_USERS_SUCCESS',
  INVITE_USERS_FAILURE = 'app/userManagementPage/INVITE_USERS_FAILURE',
  SET_USER_STATUS = 'app/userManagementPage/SET_USER_STATUS',
  USER_STATUS_REQUEST = 'app/userManagementPage/USER_STATUS_REQUEST',
  USER_STATUS_SUCCESS = 'app/userManagementPage/USER_STATUS_SUCCESS',
  USER_STATUS_FAILURE = 'app/userManagementPage/USER_STATUS_FAILURE',
  APPLY_FILTER = 'app/userManagementPage/APPLY_FILTER',
  CHECK_EMAIL_SUBMIT = 'app/userManagementPage/CHECK_EMAIL_SUBMIT',
  CHECK_EMAIL_REQUEST = 'app/userManagementPage/CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS = 'app/userManagementPage/CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE = 'app/userManagementPage/CHECK_EMAIL_FAILURE',
}

export default ActionTypes;
