/**
 *
 * UserManagementPage
 *
 */

import { ContainerState, RootState } from './types';
import { Dispatch, compose } from 'redux';
import React, { Fragment } from 'react';
import { applyFilter, inviteUsers, setUserStatus, checkEmailSubmit } from './actions';

import Button from 'components/TrackingComponents/Button';
import { FormattedMessage } from 'react-intl';
import { Group } from 'containers/GroupDetailsPage/types';
import { Helmet } from 'react-helmet';
import InviteForm from './InviteForm';
import InviteIcon from './Invite.svg';
import Modal from 'components/Modal/Modal';
import PromptMessage from 'components/PromptMessage/PromptMessage';
import SentIcon from './Sent.svg';
import UserFilter from './UserFilter';
import UserTable from './UserTable';
import { connect } from 'react-redux';
// import { FormattedMessage } from "react-intl";
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import { permissionGroups } from 'utils/permissionMap';
import { push } from 'connected-react-router';
import reducer from './reducer';
import saga from './saga';
import selectUserManagementPage from './selectors';
import styled from 'styles/styled-components';
import translations from 'translations';
import { uniq } from 'lodash';
import utilsMessages from 'utils/messages';

const ModalMessage = styled.div`
  text-align: center;
  margin-top: 20pt;
  margin-bottom: 18pt;
  font-size: 16px;
`;
const UserFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 18px;
  & > div {
    flex-grow: 1;
  }
`;
interface OwnProps {
  userManagementPage: ContainerState;
}

interface StateProps {}

interface DispatchProps {
  applyFilter: (data: any) => void;
  onInviteUsers: (data: any) => void;
  onSetUserStatus: (data: any) => void;
  gotoUserDetails: (data: any) => void;
  checkEmail: (data: any) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class UserManagementPage extends React.Component<Props> {
  public state = {
    isOpenInvitation: false,
    isOpenSubmitInviteModal: false,
  };

  public componentWillReceiveProps(nextProps) {
    const { inviteLoading: nInviteLoading, inviteError } = nextProps.userManagementPage;
    if (!nInviteLoading && nInviteLoading !== this.props.userManagementPage.inviteLoading) {
      if (!inviteError) {
        this.setState({ isOpenSubmitInviteModal: true });
      }
      this.onCloseModal();
    }
  }

  public onCloseModal = () => this.setState({ isOpenInvitation: false });
  public onOpenModal = () => this.setState({ isOpenInvitation: true });
  public onSubmitInvitation = (emails: string[]) => {
    if (emails.length) {
      this.props.onInviteUsers({
        emails: emails,
      });
    }
  };
  public onSetUserStatus = (user: any, status: any) => {
    this.props.onSetUserStatus({
      id: user.id,
      email: user.email,
      status: status,
      name: user.name,
      telephone: user.telephone,
      imageUrl: user.imageUrl,
    });
  };

  public getUserResult = () => {
    const { users, filter } = this.props.userManagementPage;

    return !filter.query && !filter.groups.length && !filter.permissions.length
      ? users.data
      : users.data.filter((user) => {
          if (filter.query) {
            return (user.name || user.email.split('@')[0]).toLowerCase().indexOf(filter.query.toLowerCase()) > -1;
          }
          if (filter.groups.length) {
            let foundGroupCounter = 0;
            user.userGroups.forEach((g: Group) => {
              if (filter.groups.indexOf(g.groupId) > -1) {
                foundGroupCounter += 1;
              }
            });
            return foundGroupCounter === filter.groups.length;
          }
          if (filter.permissions.length) {
            let foundPermissionCounter = 0;
            const fullPermissions = user.permissions.map((permission) => permission.action);
            user.userGroups.forEach((group) => {
              group.permissions.forEach((permission) => {
                if (fullPermissions.indexOf(permission.action) === -1) {
                  fullPermissions.push(permission.action);
                }
              });
            });
            const selectedPermissions = uniq(
              permissionGroups
                .filter((pg) => filter.permissions.indexOf(pg.id) > -1)
                .reduce((prev, crr) => prev.concat(crr.permissions), [] as string[]),
            );

            fullPermissions.forEach((action: string) => {
              if (selectedPermissions.indexOf(action) > -1) {
                foundPermissionCounter += 1;
              }
            });
            return foundPermissionCounter === selectedPermissions.length;
          }
          return false;
        });
  };

  public render() {
    const { users, filter, validationEmailMessage, isCheckingEmail, inviteLoading } = this.props.userManagementPage;
    const groups: Group[] = [];
    users.data.forEach((user) => {
      (user.userGroups || []).forEach((ug) => {
        if (!groups.find((g) => g.groupId === ug.groupId)) {
          groups.push(ug);
        }
      });
    });

    const usersResult = this.getUserResult();

    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        {/* <Row type="flex" justify="space-between" align="middle">
          <PageHeader><FormattedMessage {...messages.header} /></PageHeader>
        </Row> */}
        <UserFilterWrapper>
          <UserFilter
            groups={groups}
            permissionGroups={permissionGroups}
            filter={filter}
            onSearch={this.props.applyFilter}
          />
          <Button
            trackingCategory="User Management | Button"
            trackingAction="Invite Colleagues"
            type="primary"
            onClick={this.onOpenModal}
            size="large"
          >
            <FormattedMessage {...messages.inviteUser} />
          </Button>
        </UserFilterWrapper>
        <UserTable users={usersResult} onGotoUserDetails={this.props.gotoUserDetails} loading={users.loading} />
        <Modal
          headerLabel={translations(messages.inviteNewUser)}
          headerIcon={InviteIcon}
          isOpen={this.state.isOpenInvitation}
        >
          <InviteForm
            onCancel={this.onCloseModal}
            onSubmit={this.onSubmitInvitation}
            checkEmail={this.props.checkEmail}
            isCheckingEmail={isCheckingEmail}
            inviteLoading={inviteLoading}
            validationEmailMessage={validationEmailMessage}
          />
        </Modal>
        <Modal
          isOpen={this.state.isOpenSubmitInviteModal}
          submitLabel={translations(utilsMessages.close)}
          onSubmit={() => this.setState({ isOpenSubmitInviteModal: false })}
        >
          <PromptMessage
            icon={SentIcon}
            message={[translations(messages.congrat), translations(messages.invitationSent)]}
          />
          <ModalMessage>{translations(messages.askColleagueToCheckMailSentence)}</ModalMessage>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  userManagementPage: selectUserManagementPage(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    applyFilter: (data: any) => dispatch(applyFilter(data)),
    onInviteUsers: (data: any) => dispatch(inviteUsers(data)),
    onSetUserStatus: (data: any) => dispatch(setUserStatus(data)),
    gotoUserDetails: (userId: string) => dispatch(push(`/user-details/${userId}`)),
    checkEmail: (data: any) => dispatch(checkEmailSubmit(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'userManagementPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({
  key: 'userManagementPage',
  saga: saga,
});

export default compose(withReducer, withSaga, withConnect)(UserManagementPage);
