/*
 *
 * UserManagementPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const users = createAsyncAction(
  ActionTypes.FETCH_USERS_REQUEST,
  ActionTypes.FETCH_USERS_SUCCESS,
  ActionTypes.FETCH_USERS_FAILURE,
)<void, {}, Error>();

export const inviteUsers = (data: any) => action(ActionTypes.INVITE_USERS, data);
export const invite = createAsyncAction(
  ActionTypes.INVITE_USERS_REQUEST,
  ActionTypes.INVITE_USERS_SUCCESS,
  ActionTypes.INVITE_USERS_FAILURE,
)<void, {}, Error>();

export const checkEmailSubmit = (values: any) => action(ActionTypes.CHECK_EMAIL_SUBMIT, values);
export const checkEmail = createAsyncAction(
  ActionTypes.CHECK_EMAIL_REQUEST,
  ActionTypes.CHECK_EMAIL_SUCCESS,
  ActionTypes.CHECK_EMAIL_FAILURE,
)<{}, {}, {}>();

export const setUserStatus = (data: any) => action(ActionTypes.SET_USER_STATUS, data);
export const applyFilter = (data: any) => action(ActionTypes.APPLY_FILTER, data);
export const userStatus = createAsyncAction(
  ActionTypes.USER_STATUS_REQUEST,
  ActionTypes.USER_STATUS_SUCCESS,
  ActionTypes.USER_STATUS_FAILURE,
)<void, {}, Error>();
