import { ApplicationRootState } from 'types';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the userManagementPage state domain
 */

const selectUserManagementPageDomain = (state: ApplicationRootState) => {
  return state ? state.userManagementPage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by UserManagementPage
 */

const selectUserManagementPage = () =>
  createSelector(selectUserManagementPageDomain, substate => {
    return substate;
  });

export default selectUserManagementPage;
export { selectUserManagementPageDomain };
