/*
 *
 * UserManagementPage reducer
 *
 */

// import {combineReducers} from 'redux';

import { ContainerActions, ContainerState } from './types';

import ActionTypes from './constants';

export const initialState: ContainerState = {
  users: {
    loading: true,
    data: [],
  },
  filter: {
    query: '',
    groups: [],
    permissions: [],
  },
  isCheckingEmail: false,
  validationEmailMessage: {},
  inviteLoading: false,
  inviteError: false,
};

function usersPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;

  switch (type) {
    case ActionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: {
          loading: false,
          data: payload,
        },
      };
    case ActionTypes.FETCH_USERS_FAILURE:
      return {
        ...state,
        users: {
          loading: false,
          data: state.users.data,
        },
      };
    case ActionTypes.USER_STATUS_SUCCESS:
      const users = state.users;
      const userIndex = users.data.findIndex(u => u.id === payload.id);
      users.data[userIndex] = {
        ...users.data[userIndex],
        ...payload,
      };
      return {
        ...state,
        users: users,
      };
    case ActionTypes.INVITE_USERS_REQUEST:
      return {
        ...state,
        inviteError: false,
        inviteLoading: true,
      };
    case ActionTypes.INVITE_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: payload,
        },
        inviteError: false,
        inviteLoading: false,
      };
    case ActionTypes.INVITE_USERS_FAILURE:
      return {
        ...state,
        inviteError: true,
        inviteLoading: false,
      };
    case ActionTypes.APPLY_FILTER:
      return {
        ...state,
        filter: {
          ...payload,
        },
      };
    case ActionTypes.CHECK_EMAIL_REQUEST:
      return {
        ...state,
        isCheckingEmail: true,
        validationEmailMessage: { ...state.validationEmailMessage, [payload.id]: '' },
      };
    case ActionTypes.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        isCheckingEmail: false,
      };
    case ActionTypes.CHECK_EMAIL_FAILURE:
      return {
        ...state,
        isCheckingEmail: false,
        validationEmailMessage: { ...state.validationEmailMessage, [payload.id]: payload.message },
      };
    default:
      return state;
  }
}

export default usersPageReducer;
